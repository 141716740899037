#dunas {
    position:relative;
    width:100%;
    height:5vh;
    box-sizing:border-box;
    margin:auto;
    padding-top:25vh;
    text-align:center;
    overflow:hidden;
}

#dunas:before,#dunas:after {
    content:" ";
    display:block;
    position:absolute;
    width:100%;
    height:50vh;
    background:rgba(138,176,63,1);
    box-shadow:-0.3rem -0.1rem 0 0 rgba(77,70,55,0.2);
    border-radius:50%;
}
#dunas:before {
    bottom:-30vh;
    left:-50%;  
}
#dunas:after {
    background:rgba(122,219,0,1);
    bottom:-28vh;
    right:-40%;
}

#duneleft{
    content:" ";
    display:block;
    position:absolute;
    width:100%;
    height:50vh;
    background:rgba(138,176,63,1);
    box-shadow:-0.3rem -0.1rem 0 0 rgba(77,70,55,0.2);
    border-radius:50%;
    bottom:-30vh;
    left:-50%;
    z-index: 100;
}

#duneright{
    content:" ";
    display:block;
    position:absolute;
    width:100%;
    height:50vh;
    background:rgba(122,219,0,1);
    box-shadow:-0.3rem -0.1rem 0 0 rgba(77,70,55,0.2);
    border-radius:50%;
    bottom:-28vh;
    right:-40%;
    z-index: 120;
}
.textduneleft{
    position:absolute;
    bottom:-28vh;
    left:-10%;
    z-index: 140;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #fefefe;
  }
  
  li {
    float: left;
  }
  
  li a{
    display: block;
    color: #505050;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }
  
  li a:hover {
    background-color: #9F2914;
  }
  