*, :before, :after {
    box-sizing: border-box;
}
form {
    width: 320px;
    margin: 45px auto;
}
form h1 {
    font-size: 3em;
    font-weight: 300;
    text-align: center;
    color: #c6c6c6;
}
form h5 {
    text-align: center;
    text-transform: uppercase;
    color: #c6c6c6;
}
form hr.sep {
    background: #c6c6c6;
    box-shadow: none;
    border: none;
    height: 2px;
    width: 25%;
    margin: 0px auto 45px auto;
}
form .emoji {
    font-size: 1.2em;
}
.group {
    position: relative;
    margin: 45px 0;
}
textarea {
    resize: none;
}
input, textarea {
    background: none;
    color: #c6c6c6;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 320px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c6;
}
input:focus, textarea:focus {
    outline: none;
}
input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    top: -14px;
    font-size: 12px;
    color: #cfcfcf;
}
input:focus ~ .bar:before, textarea:focus ~ .bar:before {
    width: 320px;
}
input[type="password"]{
    letter-spacing: 0.3em;
}
label {
    color: #c6c6c6;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
}
.bar {
    position: relative;
    display: block;
    width: 320px;
}
.bar:before {
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #cfcece;
    transition: 300ms ease all;
    left: 0%;
}
.btn {
    font-family: Roboto;
    background: #fff;
    color: #fefefe;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-decoration: none;
    outline: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.btn:hover {
    color: #8b8b8b;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
}
.btn.btn-link {
    background: #AE3934;
    color: #fefefe;
}
.btn.btn-link:hover {
    background: #9F2914;
    color: #fefefe;
}
.btn.btn-submit {
    background: #9F2914;
    color: #efefef;
}
.btn.btn-submit:hover {
    background: #9F2914;
    color: #fefefe;
}
.btn.btn-cancel {
    background: #eee;
}
.btn.btn-cancel:hover {
    background: #e1e1e1;
    color: #8b8b8b;
}
.btn-box {
    text-align: center;
    margin: 50px 0;
}